import { render, staticRenderFns } from "./BoxPaymentInstruction.vue?vue&type=template&id=96a0c622&scoped=true&"
import script from "./BoxPaymentInstruction.vue?vue&type=script&lang=ts&"
export * from "./BoxPaymentInstruction.vue?vue&type=script&lang=ts&"
import style0 from "./BoxPaymentInstruction.scss?vue&type=style&index=0&id=96a0c622&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a0c622",
  null
  
)

export default component.exports