
























import { Component, Vue, Prop } from 'vue-property-decorator';

import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';
import { CurrencyNumberFormat } from '@/share/Util/NumberFormat';

@Component
export default class BoxPaymentPrice extends Vue {
  @Prop() value!: number;
  @Prop() date!: string;
  @Prop({ default: '' }) paymentMethod!: string;

  get formattedValue() {
    if (!this.value) return '';

    return CurrencyNumberFormat().format(this.value);
  }

  get formattedDate() {
    if (!this.date) return '';

    const { day, month, year } = getDataSeparatedFromDateTime(this.date);

    return `${day}/${month}/${year}`;
  }

  handleDiscount(value: string) {
    return value;
  }
}
